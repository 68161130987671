import React from "react";
import { Link } from "gatsby";

const StartButton = (props) => {
  const to = props.to || '#';
  const text = props.text || 'Get Started';

  return (
    <Link to={to}
      className={classNames(
        props.className,
        'inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-highlight hover:bg-highlight-dark'
      )}
    >
      {text}
    </Link>
  );
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default StartButton;
