import React from "react";

const FeatureList = ({ topFade = true, bottomFade = true, alternate = false, inverse = false, children }) => {
  let alternateStart = inverse ? 0 : 1; // Whether we want to start with grey or white

  return (
    <section className={'relative overflow-hidden' + (alternate && inverse ? ' bg-gray-100' : ' bg-white')}>
      {
        topFade &&
          <div aria-hidden="true" className="absolute z-0 inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"></div>
      }
      <div className="relative z-10 ">
        {
          React.Children.map(children || null, (feature, idx) => (
            <div className={'relative feature py-8' + (alternate && (idx % 2  === alternateStart) ? ' bg-gray-100' : '')} key={idx}>
              <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid grid-cols-1 lg:grid-cols-2 lg:grid-flow-row-dense lg:gap-24">
                {feature}
              </div>
            </div>
          ))
        }
      </div>
      {
        bottomFade &&
          <div aria-hidden="true" className="absolute z-0 inset-x-0 bottom-0 h-48 bg-gradient-to-b from-white to-gray-100"></div>
      }
    </section>
  );
}

export default FeatureList;
// export { default as Feature } from "./feature";
export { default as Fixed } from "./Fixed";
export { default as Fluid } from "./Fluid";
