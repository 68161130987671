import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Fixed } from "@components/feature-list";
import StartButton from "@components/StartButton";

const MyReceiptRewards = ({ getStarted, reverse }) => {
  const images = useStaticQuery(graphql`
    query {
      xs: file(name: { eq: "shopping-centre-image-7" }) {
        sharp: childImageSharp {
          fixed(width: 438) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      sm: file(name: { eq: "shopping-centre-image-7" }) {
        sharp: childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      md: file(name: { eq: "shopping-centre-image-7" }) {
        sharp: childImageSharp {
          fixed(width: 560) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const sources = [
    images.xs.sharp.fixed,
    {
      ...images.sm.sharp.fixed,
      media: `(min-width: 640px AND max-width: 767px)`,
    },
    {
      ...images.md.sharp.fixed,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <Fixed
      id="physical-eftpos"
      description={<Description getStarted={!!getStarted}/>}
      image={sources}
      reverse={reverse} />
  );
}

const Description = ({ getStarted }) => (
  <div>
    <div>
      <h2>
        <span className="block text-base text-highlight font-semibold tracking-wide uppercase">
          Engagement | Loyalty
        </span>

        <span className="block text-3xl font-bold tracking-tight text-gray-900 mt-1">
          My Receipt Rewards
        </span>
      </h2>
      <p className="mt-4 text-lg text-gray-500">
        Allows a simple, cost-effective method of distributing funds and rewards whilst driving
        traffic to nominated retailers. Automate your rewards, incentives or stimulus programs
        in our consumer self-service portal.
      </p>
      <p className="mt-4 text-lg text-gray-500">
        The consumer purchases a product, takes a photo of the receipt, add a few personal details,
        and hits send. Waivpay processes approval and has monies back to the customer's account
        within 3-5 days.
      </p>
      <p className="mt-4 text-lg text-gray-500">
        Our enterprise-grade application can manage programs of all sizes, from a few hundred 
        claims to hundreds of thousands of claims. We deliver detailed program reports, like
        time of day, products purchased and value approved. The engine provides partners with
        a real-time dashboard that tracks every cent.
      </p>
      {
        getStarted &&
          <div className="mt-6">
            <StartButton to="/giftcards" />
          </div>
      }
    </div>
  </div>
);

export default MyReceiptRewards;
