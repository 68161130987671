import React from "react";
import ImageTag from "gatsby-image";

export default function Fixed({ id, description, image, reverse }) {
  return (
    reverse ?
      <ReverseFeature id={id} description={description} image={image}/>
    :
    <Feature id={id} description={description} image={image}/>
  );
};

// Image on right
const ReverseFeature = ({ id, description, image }) => (
  <>
    <div className="md:h-[60vh] flex inline-block align-middle items-centre align-text-middle max-w-7xl lg:max-w-none mx-6 md:mx-auto my-auto sm:px-6 md:px-16 lg:px-0 lg:mx-0">
      {
        id ?
          <span id={id} className="flex my-auto inline-block align-middle ">{description}</span>
          :
          <span>description</span>
      }
    </div>
    <div className="grid mx-auto my-6 md:my-auto inline-block align-middle items-centre">
      <div className="grid mx-auto align-center lg:relative lg:h-full">
        <ImageTag fixed={image} style={{ position: undefined }} className="flex w-full relative lg:right-0 lg:h-full lg:w-auto lg:max-w-none" />
      </div>
    </div>
  </>
);

// Image on left
const Feature = ({ id, description, image }) => (
  <>
    <div className="md:h-[60vh] flex inline-block align-middle items-centre align-text-middle max-w-7xl lg:max-w-none mx-6 md:mx-auto my-auto sm:px-6 md:px-16 lg:px-0 lg:mx-0 lg:col-start-2">
      {
        id ?
          <span id={id} className="flex my-auto inline-block align-middle ">{description}</span>
          :
          <span>description</span>
      }
    </div>
    <div className="grid mx-auto my-6 md:my-auto inline-block align-middle items-centre lg:col-start-1 content-center">
      <div className="grid mx-auto align-center lg:relative lg:h-full">
        <ImageTag fixed={image} style={{ position: undefined }} className="grid-cols-1 relative lg:right-0 lg:h-full lg:w-auto lg:max-w-none" />
      </div>
    </div>
  </>
);

